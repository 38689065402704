@import "variables";
@import "mixins";

.change-request {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $white;
  outline: none;
  overflow: hidden;
  p {
    word-break: break-all;
  }
  .change-request-header {
    height: 72px;
    flex: 0 0 72px;
    justify-content: space-between;
    align-items: center;
    padding: 7px 16px 7px 19px;
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(34, 48, 73, 0.15), 0 4px 12px 0 rgba(28, 33, 43, 0.17);
    z-index: 1;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    h3 {
      font: $font24 $textFontBold;
      margin: 0 19px;
      font-weight: bold;
      color: $blue;
      flex: 1;
    }

    .button-cancel {
      @include icon-button2(18px, 18px, "../../public/images/icons/icon_cancel_24_white.svg", $blue);
    }

    .button-send {
      @include pure-button(auto, auto);
      font-size: $font16;
      font-weight: 500;

      &:disabled {
        color: #c2c3c4;
      }
    }
  }

  .original-text {
    padding: 24px 16px;
    font: $font16 $textFontFam;
    line-height: $defaultLineHeight;
    color: $black;
  }

  .divider {
    width: 100%;
    height: 1px;
    box-shadow: inset 0 -0.5px 0 0 #d3d6db;
  }

  .input-wrapper {
    display: flex;
    flex: 1;
    min-height: 100%;
    max-height: 100%;
    padding: 96px 16px 24px;
    background-color: $white;
    font: $font20 $textFontBold;
    font-weight: 500;
    line-height: $defaultLineHeight;
    color: #37393d;

    textarea {
      flex: 1;
      padding: 0;
      @include placeholder-style {
        font: $font20 $textFontFam;
      }

      min-height: 100%;
      width: 100%;
      border: none;
      outline: none;
    }
  }
}
