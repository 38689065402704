@import "../variables.scss";
@import "../../sass/mixins.scss";

.textAreaInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .label {
        font-size: $font14;
        color: $textFieldGray;
        line-height: 2;
    }

    .input {
        font-size: $font16;
        color: $black;
        line-height: 1.5;
        height: 5.75rem;
        background: $quaternaryBlue;
        caret-color: $primaryBlue;
        border: none;
        border-radius: 8px;
        width: 100%;
        padding: 0.5rem;
        resize: vertical;

        &:focus {
            outline: 1px solid $primaryBlue;
        }
    }

    &.warning {
        .input {
            caret-color: $darkestOrange;
            background-color: $orange50;

            &:focus {
                outline: 1px solid $darkestOrange;
            }
        }

    }
}