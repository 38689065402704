@mixin forSmallPhoneOnly {
    // Equivalent to ~599px at root font-size of 16
    @media (max-width: 37.49rem) and (max-height: 37.49rem) {
        @content
    };
}

@mixin forPhoneOnly {
    // Equivalent to ~599px at root font-size of 16
    @media (max-width: 37.49rem) {
      @content
    };
}

@mixin forTabletPortraitUp {
    // Equivalent to  600px at root font-size of 16
    @media (min-width: 37.5rem) {
        @content
    };
}

@mixin forTabletLandscapeUp {
    // Equivalent to 900px at root font-size of 16
    @media (min-width: 56.25rem) {
        @content
    };
}

@mixin forDesktopUp {
    // Equivalent to 1200px at root font-size of 16
    @media (min-width: 75rem) {
        @content
    };
}

@mixin hover {
    @media (hover: hover) {
        &:hover {
            @content
        }
    };
}