@import "../../sass/variables.scss";
@import "../../sass/mixins";

.toast {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    z-index: 1;
    padding: 1rem;
    border-radius: 8px;

    .icon,
    .emoji {
        flex-shrink: 0;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.25rem;
        border-radius: 50px;
    }

    .emoji {
        font-size: $font18;
    }

    &.successNotification {
        color: $darkestGreen;
        border: 1px solid $darkestGreen;
        background-color: $lightestGreen;

        .icon {
            color: $darkMidGreen;
            background: $lightGreen;
        }
    }

    &.errorNotification {
        color: $darkRed;
        border: 1px solid $darkRed;
        background-color: $lightRed;

        .icon {
            color: $darkRed;
            background: $red;
        }
    }

    &.infoNotification {
        color: $black;
        border: 1px solid $secondaryBlue;
        background-color: $quaternaryBlue;

        .icon {
            background: $primaryBlue;
            color: $quaternaryBlue;
        }
    }

    @include forTabletPortraitUp {
        width: 23rem;
    }
}