@import "../variables.scss";

.spinner {
    border: 3px solid $white;
    border-top: 3px solid $blue300;
    border-right: 3px solid $blue300;
    border-bottom: 3px solid $blue300;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 2s linear infinite;
    &.secondary {
        border: 3px solid $blue400;
        border-top: 3px solid $blue100;
        border-right: 3px solid $blue100;
        border-bottom: 3px solid $blue100;
    }
    &.tertiary {
        border: 3px solid $blue200;
        border-top: 3px solid $blue400;
        border-right: 3px solid $blue400;
        border-bottom: 3px solid $blue400;
    }
    &.quaternary  {
        border: 3px solid transparent;
        border-top: 3px solid $blue300;
        border-right: 3px solid $blue300;
        border-bottom: 3px solid $blue300;
    }
    &.small {
        border-width: 2px;
        width: 0.75rem;
        height: 0.75rem;
    }
    &.medium {
        width: 1.5rem;
        height: 1.5rem;
    }
    &.large {
        border-width: 5px;
        width: 3rem;
        height: 3rem;
    }
    &.larger {
        border-width: 8px;
        width: 8.875rem;
        height: 8.875rem;
    }
    &.dots {
        height: unset;
        border: unset;
        width: 0.5rem;
        margin: 0 1rem;
        aspect-ratio: 1;
        border-radius: 50%;
        animation: loading3Dots 1s infinite linear alternate;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes loading3Dots {
    0%  {box-shadow: 0.75rem 0 $blue50, -0.75rem 0 $blue400;background: $blue50 }
    33% {box-shadow: 0.75rem 0 $blue50, -0.75rem 0 $blue400;background: $blue400}
    66% {box-shadow: 0.75rem 0 $blue400,-0.75rem 0 $blue50; background: $blue400}
    100%{box-shadow: 0.75rem 0 $blue400,-0.75rem 0 $blue50; background: $blue50 }
}

  