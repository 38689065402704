@import "../../sass/variables.scss";
@import "../../sass/mixins";

.sectionHeader {
    width: fit-content;
    background: $quaternaryBlue;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;

    .text {
        line-height: 1;
    }

    &.warning {
        background: $midOrange;
    }

}