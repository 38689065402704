@import '../../sass/mixins.scss';

.container {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;

  @include forTabletPortraitUp {
    width: min(100%, 37rem);
    max-width: 25rem;
  }
}