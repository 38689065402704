@import "../../sass/variables.scss";
@import "../../sass/mixins";

.splashPreloader {
    display: inline-block;
    vertical-align: middle;
    align-self: center;
    border: 3px solid transparent;
    border-top: 3px solid $primaryBlue;
    border-right: 3px solid $primaryBlue;
    border-bottom: 3px solid $primaryBlue;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}