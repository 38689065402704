@import "variables";
@import "mixins";

.qr-and-sms-module {
  display: flex;
  width: calc(100vw - 70px);
  max-width: 730px;
  padding: 20px 5px;
  margin: 10px 0;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid;
  border-radius: 5px;
  .react-tel-input {
    width: 300px;
    margin: 0 auto;
    .country-name {
      color: $black;
    }
  }
}

.error-message {
  color: $red;
}
.success-message {
  color: $green;
}

.desktop-view {
  main {
    max-width: 760px;
    margin: 0 auto;
    &.signing {
      opacity: 35%;
    }
    .data-column {
      display: flex;
      flex-wrap: wrap;
      .info {
        max-width: 728px;
      }
      .column-item {
        max-width: 364px;
        width: 100%;
        @include betaSize {
          width: calc(100vw - 34px);
        }
        .info {
          max-width: 364px;
          width: 100%;
          @include betaSize {
            width: calc(100vw - 34px);
          }
        }
      }
      .label {
        width: 100%;
      }
    }
  }
  .footer-buttons {
    max-width: 760px;
    margin: 0 auto;
    button {
      min-width: 200px;
      margin: 0 auto;
    }
    @include betaSize {
      margin: 0;
      button {
        width: 100%;
      }
    }
  }
  .special-link {
    font-size: 16px;
    line-height: 1.5;
    color: $white;
    text-decoration: none;
    margin: 10px 0 0 0;
    cursor: pointer;
    text-decoration: underline;
    &.disabled {
      color: $gray;
    }
  }
  .sign {
    top: 0;
    @include betaSize {
      top: 20%;
    }
  }
}

.signature-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    background: white;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    min-width: 200px;
  }
  p {
    margin: 0;
  }
}
