@import "variables";
@import "mixins";

.agent {
  background-color: $white;
  outline: none;
  overflow: hidden;
  border-radius: 4px;
  width: 311px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .agent-details {
    text-align: center;
    display: flex; //flex
    flex-direction: column;

    .agent-photo {
      margin-top: 25px;
      img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 96px;
        max-height: 96px;
        border-radius: 50%;
      }
    }

    .agent-description {
      font: $font11 $textFontFam;
      opacity: 0.5;
      color: #37393d;
      font-weight: 300;
      margin-top: 14px;
    }

    .agent-name {
      font: $font40 $textFontBold;
      line-height: $defaultLineHeight;
      color: #37393d;
      margin-top: 13px;
    }

    .agent-agency {
      font: $font16 $textFontFam;
      opacity: 0.5;
      color: #37393d;
      margin-top: 12px;
    }

    .agent-contacts {
      font: $font16 $textFontFam;
      color: #37393d;
      font-weight: 500;
      line-height: $defaultLineHeight;
      margin-top: 20px;
      margin-bottom: 28px;
    }

    .agent-contacts a {
      color: #37393d;
    }
  }

  button {
    @include pure-button(100%, 100%);

    background: linear-gradient(331deg, #823be2 0%, #7359e7 34%, #6f61e9 35%, $backgroundGradientBlue 100%);
    color: $white;
    padding-top: 18px;
    padding-bottom: 18px;
    font: $font20 $textFontBold;
  }
}

.agent-modal-faded {
  height: 100%;
  outline: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
