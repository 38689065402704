@import "../../sass/variables.scss";
@import "../../sass/mixins";

.modal {
    width: 100%;
    height: 100%;
    height: var(--windowHeight);
    height: 100dvh;

    &:focus-visible {
        outline: none;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($white, 1);
    z-index: 1;
    display: flex;
    flex-direction: column;
}