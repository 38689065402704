@import "variables";
@import "mixins";

.splash-screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-image: linear-gradient(331deg, #833be2, $backgroundGradientBlue);

  &.splash-screen-index {
    background: url("../../public/images/icons/backgroud.svg") no-repeat bottom right, linear-gradient(331deg, #833be2, $backgroundGradientBlue);
  }

  .logo {
    height: 90px;
    margin-top: 13em;
    text-align: center;

    &.logo-index {
      margin-top: 115px;
    }
  }

  .splash-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    p {
      font: $font20 $textFontFam;
      line-height: $defaultLineHeight;
      text-align: center;
      color: $white;
      width: 220px;
    }
  }
}
