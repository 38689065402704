@import "variables";
@import "mixins";

.popup-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);
  visibility: hidden;
  opacity: 0;
  transition: .2s;
  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.11), 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  min-width: 255px;
  text-align: center;
}

.popup-body {
  padding: 16px;
  font-size: $font18;
  color: $darkGray;
  line-height: $defaultLineHeight;
}

.popup-footer {
  padding: 16px;
  border-top: 1px solid $gray;
  button {
    color: $blue;
    border: none;
    background-color: transparent;
    font-size: $font18;
    font-weight: 500;
    text-transform: uppercase;
  }
}