@import "variables";
@import "mixins";

.outdated-lease-screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-image: linear-gradient(331deg, #833be2, $backgroundGradientBlue);
  background: url("../../public/images/icons/backgroud.svg") no-repeat bottom right, linear-gradient(331deg, #833be2, $backgroundGradientBlue);

  .logo {
    height: 90px;
    margin-top: 13em;
    text-align: center;

    &.logo-index {
      margin-top: 115px;
    }
  }

  .outdated-lease-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    h2 {
      font-size: 1.4em;
    }
  }

  .outdated-lease-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    p {
      font: $font20 $textFontFam;
      line-height: $defaultLineHeight;
      text-align: center;
      color: $white;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 1em;
    }

    strong {
      font-family: $textFontBold;
    }

    .expired-message {
      margin: 0 2.5rem;
      font: $font20 $textFontFam;
      p {
        margin-left: 0;
        margin-right: 0;
        text-align: left;
      }
    }
  }
}
