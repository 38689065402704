@import "variables";
@import "mixins";

.request-modal {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $white;
  outline: none;
  overflow: hidden;
}
