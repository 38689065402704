@import "variables";
@import "mixins";

.change-location {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  background-color: $white;
  outline: none;
  overflow: hidden;

  .change-location-header {
    height: 59px;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    display: flex;

    .button-cancel {
      @include icon-button2(24px, 24px, "../../public/images/icons/icon_cancel_24_white.svg", $blue);
    }

    .button-erase {
      @include icon-button(24px, 24px, "../../public/images/icons/icon_eraser.svg");
    }
  }

  .input-wrapper {
    padding: 20px 16px;
    background-color: $white;
    box-shadow: 0 4px 6px 0 rgba(28, 33, 43, 0.17);
    z-index: 1;

    input {
      width: 100%;
      border: none;
      font-size: $font20;
      font-weight: 500;
    }
  }

  .map-wrapper {
    width: 100%;
    height: auto;
    min-height: 216px;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    div:first-of-type {
      flex: 1;
      height: auto;
    }

    .button-wrapper {
      position: absolute;
      bottom: 16px;
      left: 16px;
      width: calc(100vw - 32px);
      height: 56px;

      button {
        @include ellipse-button(100%, 100%);

        color: $white;
        background: linear-gradient(to left, #7d69ed, #427cff);
      }
    }
  }

  .list {
    padding: 0 16px;

    .item {
      font: $font18 $textFontFam;
      font-weight: 500;
      line-height: $defaultLineHeight;
      color: #37393d;
      width: 100%;
      padding: 20px 0;
      box-shadow: inset 0 -0.5px 0 0 #d3d6db;
      text-transform: capitalize;

      &.static {
        color: $blue;
        display: flex;
        align-items: baseline;

        .icon {
          @include icon(8px, 16px, $blue, "../../public/images/icons/pin.svg");
        }

        .text {
          margin-left: 15px;
          flex: 1;
        }
      }
    }
  }

  .no-results {
    font-size: 20px;
    font-weight: 500;
    line-height: $defaultLineHeight;
    text-align: center;
    color: #000;
    margin-top: 180px;
  }

  .text-bold {
    font-family: $textFontBold;
  }
}
