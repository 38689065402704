@import "variables";
@import "mixins";

.question-modal {
  display: flex;
  flex-direction: column;
  width: 255px;
  height: 135px;
  background-color: $white;
  outline: none;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .text {
    font: $font18 $textFontFam;
    line-height: $defaultLineHeight;
    padding: 16px 24px 20px;
    text-align: center;
    box-shadow: inset 0 -0.5px 0 0 #d3d6db;
    color: #000;
  }

  .buttons {
    display: flex;
    padding: 10px 0;

    button {
      @include pure-button(100%, 100%);

      &.continue {
        color: $blue;
        border-right: 1px solid #d3d6db;
      }

      &.cancel {
        color: $red;
      }
    }
  }

}
