@import "../../variables.scss";

$borderRadius: 8px;

.container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .label {
        font-size: $font14;
        line-height: $font25;
        font-weight: $lightFontWeight;
        color: $gray700;
    }

    .container {
        font-family: $textFontFam;

        background-color: $blue100;
        border-radius: $borderRadius;

        display: flex;
        flex-direction: row;
        align-items: center;

        &:focus-within {
            outline: 1px solid $blue400;
        }
  
      .input {
        flex: 1;

        background: none;
        font-size: $font16;
        line-height: $font25;
        font-family: $textFontFam;
        box-sizing: border-box;
        border: none;
        color: $black;
        letter-spacing: -0.6px;
        caret-color: $blue400;
        outline: none;
        padding: 0.5rem 0.5rem 0.5rem 3rem;
        height: 2.5625rem;

        &:disabled {
            background: $gray400;
            color: $gray800;
        }
      }
      
      .dropdown {
        font-size: $font16;
      }
      .button {
        // This is to override default styles from react-phone-input-2
        border: none;
        background-color: transparent !important;
        border-radius: $borderRadius;

        &>div:first-child {
            border: none;
            background-color: transparent;
            border-radius: $borderRadius;
            &:hover {
                border: none;
                background-color: transparent;
                border-radius: $borderRadius;
            }
        }
      }
    }


    &.withError {
        .label {
            color: $red300;
        }

        .container {
            &:focus-within {
                outline: 1px solid $red100;
            }
        }
        .input {
            outline: none;
            background-color: $red50;
            border-radius: $borderRadius;
        }

        .error {
            font-size: $font14;
            line-height: $font18;
            color: $red300;
        }
    }
}