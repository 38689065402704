@import "variables";
@import "mixins";

.splash-preloader {
    position: relative;
    width: 25px;
    height: 25px;
    margin-top: 80px;
    align-self: center;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    border: double 4px transparent;
    border-radius: 50%;
    background-color:inherit;
    background-image: radial-gradient(circle at top, #fff, transparent);
    background-origin: border-box;
    background-clip: content-box, border-box;
    -webkit-animation: splash-preloader 0.8s linear infinite;
    animation: splash-preloader 0.8s linear infinite;

    &.inverse {
        background-image: linear-gradient($white, $white), radial-gradient(circle at top, #6377ec, transparent);
    }
  &:after {
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    content: ' ';
    border-radius: 50%;
    background-color: #6377ec;
  }
  button & {
    margin-top: 0;
    &:after {
      background-color: inherit;
    }
  }
}



@keyframes splash-preloader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes splash-preloader {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.signing-spinner-inline {
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 1;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: signing-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
  .loader-text {
    position: absolute;
    width: 200px;
    margin: 35px 0 0 82px;
  }
}

@keyframes signing-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
