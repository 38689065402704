@import "variables";
@import "mixins";

.hide {
  display: none;
}
.wrap {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;;
  font: $font16 $textFontFam;
  color: $white;
  
  &.image {
    background: url("../../public/images/icons/backgroud.svg") no-repeat bottom right,
    linear-gradient(344deg, #833be2, $backgroundGradientBlue);
  }
  &.image-new {
    background: linear-gradient(360deg, #644CD1, #437CFF);
  }

  &.gradient {
    background: linear-gradient(331deg, #823be2 0%, #7359e7 34%, #6f61e9 35%, $backgroundGradientBlue 100%);
  }

  &.confirmed {
    background: linear-gradient(331deg, #7ed321, $backgroundGradientBlue);
  }

  &.decline {
    background: linear-gradient(29deg, #e0335e, #fe5e7d);
  }

  .wrapper {
    display: flex;
    flex: 1;
  }

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.gray-background {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  font: $font16 $textFontFam;
  color: $textFieldGray;
  background: $lighterGray;
  position: relative;
}

.toast-group {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;

  @include forTabletPortraitUp {
    width: min(100%, 37rem);
    max-width: 25rem;
  }
}