@import "variables";
@import "mixins";

footer {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    &.column {
        flex-direction: column;
    }

    &.declined {
        .button {
            &-cancel {
                button {
                    background-color: $red;

                    .icon {
                        background: white;
                    }
                }
            }

            &-success {
                button {
                    box-shadow: none;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    background-color: $red;

                    .icon {
                        background: rgba(255, 255, 255, 0.5);
                    }
                }
            }

            &-prev {
                button {
                    .icon {
                        @include icon(20px, 22px, $red, "../../public/images/icons/arrowLeft.svg");
                        margin: 0 0 0 16px;
                    }
                }
            }

            &-next {
                button {
                    .icon {
                        @include icon(20px, 22px, $red, "../../public/images/icons/arrowLeft.svg");
                        transform: scale(-1);
                        margin-right: 16px;
                    }
                }
            }
        }
    }

    &.confirmed {
        .button {
            &-success {
                button {
                    background-color: $green;

                    .icon {
                        background: white;
                    }
                }
            }

            &-cancel {
                button {
                    box-shadow: none;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    background-color: $green;

                    .icon {
                        background: rgba(255, 255, 255, 0.5);
                    }
                }
            }

            &-prev {
                button {
                    .icon {
                        @include icon(20px, 22px, $green, "../../public/images/icons/arrowLeft.svg");
                        margin-left: 16px;
                    }
                }
            }

            &-next {
                button {
                    .icon {
                        @include icon(20px, 22px, $green, "../../public/images/icons/arrowLeft.svg");
                        transform: scale(-1);
                        margin-right: 16px;
                    }
                }
            }
        }
    }

    p.faq-text {

        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        position: relative;
        margin-bottom: 9px;

        &:after {
            content: '';
            position: absolute;
            right: -25px;
            top: 0;
            width: 16px;
            height: 16px;
            background: url("../../public/images/icons/info.svg") no-repeat;
        }
    }

    span {
        text-transform: uppercase;
        color: $white;
    }

    .button-arrow {
        background: url("../../public/images/icons/arrowLeft.svg") no-repeat;
        width: 24px;
        height: 24px;
        margin-bottom: 9px;
        //todo: remove rotate when icon will ready
        transform: rotate(180deg)
    }

    .button {
        &-cancel {
            button {
                .icon {
                    @include icon(20px, 20px, $red, "../../public/images/icons/icon_cancel_24_white.svg");
                }
            }
        }

        &-success {
            button {
                .icon {
                    @include icon(20px, 20px, $green, "../../public/images/icons/icon_checkmark_white.svg");
                }
            }
        }

        &-middle {
            button, a {
                .icon {
                    //todo: change this icon
                    @include icon(20px, 25px, $violet, "../../public/images/icons/icon_addDoc.svg");
                }
            }
        }

        &-full {
            width: 100%;

            button {
                @include ellipse-button(100%, auto);
                font: $font24 $textFontBold;
                padding: 14px 0 17px;
                border-radius: 32px;
                background-color: $white;
                box-shadow: 0 5px 10px 0 rgba(53, 88, 14, 0.25), 0 2px 4px 0 rgba(53, 88, 13, 0.23);
                color: $violet;
                text-transform: none;
            }
        }

        &-green {
            width: 100%;

            button {
                @include ellipse-button(100%, auto);
                font: $font24 $textFontBold;
                padding: 14px 0 17px;
                border-radius: 32px;
                background-color: $green;
                box-shadow: 0 5px 10px 0 rgba(53, 88, 14, 0.25), 0 2px 4px 0 rgba(53, 88, 13, 0.23);
                color: $white;
                text-transform: none;
            }
        }
    }

    button,
    div.button-download-contacts > a {
        @include ellipse-button(56px, 56px);

        font: $font24 $textFontBold;
        padding: 14px 0 17px;
        border-radius: 32px;
        background-color: $white;
        color: $black;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
        text-transform: none;
    }

    div.button-download-contacts > a {
      display: block;
    }

    .agreement {
        padding: 10px 16px 12px;
        background-color: $violet-background;
        font: $font16 $textFontFam;
        line-height: $defaultLineHeight;
        margin: 0 -16px -16px;
    }
}

.footer-buttons {
    padding: 0 16px;
    p {
        margin: 20px;
        text-align: center;
        font-size: 0.875rem;
    }

    &--custom {
      padding: 0 25px;

      .footer-buttons__button {
        display: grid;
        grid-template-columns: 1fr auto;
        justify-items: center;
        align-items: center;
        padding: 1rem 0.5rem;
        color: $primaryBlue;

        &__icon {
          border-radius: 100%;
          background-color: rgba($primaryBlue, 0.2);
          color: $primaryBlue;
        }
      }

      // Increase specificity to override styles from 'footer-buttons'
      .footer-buttons__disclaimer {
        margin: 1rem 0;
        text-align: left;
        font-family: $textFontLight;
        line-height: 1.5;

        &__link {
          font-family: $textFontFam;
        }
      }
    }
    &--welcome-page {
        padding: 0 1rem;
        .footer-buttons__button {
          display: grid;
          grid-template-columns: 1fr auto;
          justify-items: center;
          align-items: center;
          padding: 1rem 0.5rem;
          color: $primaryBlue;
          width: 100%;
  
          &__icon {
            border-radius: 100%;
            background-color: rgba($primaryBlue, 0.2);
            color: $primaryBlue;
          }
        }
        .footer-buttons__contact-me-text {
          margin: 1rem 0;
          p {
            margin: 0;
            font: $font16 $textFontFam;
            line-height: 1.5;
            text-align: left;
          }
        }
      }
    
}

.footer-buttons-relative {
    padding: 0 16px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-help {
    text-align: center;
    padding: 0 85px;
}
.normal-text {
    text-transform: none !important;
}

@include forTabletPortraitUp {
  .footer-buttons--custom {
    width: 100%;

    .footer-buttons__button {
      width: 100%;
    }
  }
}
