// font family
@use "sass:math";
@import "@flk/component-library-old/variables";

// old colors - pre design system
$oldMidGray: #9d9d9b;
$backgroundGradientBlue: #44b1f6;
$lightestGray: #F6F7FB;
$lighterGray: #EEF0F3;
$midGray: #CACED9;

$defaultLineHeight: 1.5;
$smallLineHeight: 1.3;
$largeLineHeight: 1.7;

//Responsive break points
$alpha: 991px;
$beta: 767px;
