@import "variables";
@import "mixins";

header {
    height: 72px;

    &.flat {
        height: 0;
    }
    &.hide {
        display: none;
    }

    .header-navbar {
        display: flex;
        height: 72px;
        padding: 0 22px 0 18px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .nav-left {
            display: flex;
            align-items: center;

            p.nav-header {
                text-transform: uppercase;
                font: $font16 $textFontBold;
                color: $white;
            }

            .burger {
                @include icon-button(20px, 12px, "../../public/images/icons/icon_burger.svg");
                margin-right: 18px;
            }
        }

        .nav-right {
            display: flex;
            align-items: center;

            .more {
                @include icon-button(16px, 4px, "../../public/images/icons/icon_more.svg");
                margin-right: 27px;
            }

            .question {
                @include icon-button(11px, 18px, "../../public/images/icons/icon_question.svg");
            }
        }

    }

    .divider {
        width: 100%;
        height: 2px;
        background-image: linear-gradient(
                        to right,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(255, 255, 255, 0.8) 20%,
                        rgba(255, 255, 255, 0.6) 21%,
                        rgba(255, 255, 255, 0.2) 30%,
                        rgba(173, 185, 216, 0) 100%
        );
    }
}
