@import "variables";
@import "mixins";

.change-request-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #e43962;
  padding: 14px 16px;
  font-size: $font20;
  font-weight: 500;
  line-height: $defaultLineHeight;
  .icon-info {
    display: block;
    width: 24px;
    height: 24px;
    background: url("../../public/images/icons/info_white.svg") no-repeat center;
    background-size: contain;
  }
}