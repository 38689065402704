@use 'sass:math';

// color options
$white: #FFF;
$gray: #D3D6DB;
$lighterGray: #EEF0F3;
$lightestGrey: #F6F7FB;
$lightGrey: #E8E9EB;
$midGray: #CACED9;
$textFieldGray: #696C77;
$darkMidGray: #9A9EAB;
$darkGray: #272E42;
$black: #000;
$purpleBlue: #5E80EE;
$lightBlue: #49A9F5;
$darkerBlue: #0C3DAD;
$darkPrimaryBlue: #0B56FF;
$blue: #427CFE;
$primaryBlue: #427CFF;
$darkSecondaryBlue: #568FF0;
$secondaryBlue: #A0BEFF;
$quaternaryBlue: #ECF2FF;
$lighterBlue: #D0DEFF;
$textFieldBlue: #F6F9FF;
$lightestBlue: #F9FBFF;
$green: #67DB29;
$successGreen: #85D64D;
$darkMidGreen: #299400;
$darkestGreen: #2D5C1A;
$darkGreen: #5EC097;
$lightGreen: #B2DEA1;
$lightestGreen: #D8F2CE;
$red: #FE5E7D;
$midRed: #E55656;
$darkRed: #E43962;
$darkestRed: #D32E2E;
$rejectRed: #DD0000;
$lightRed: #FDD5DC;
$primaryViolet: #833BE2;
$violet: #7D47E4;
$midViolet: #7263E0;
$violet-background: #BA59E6;
$darkVibrantPurple: #654ACF;
$midVibrantPurple: #C7A5FF;
$darkestVibrantPurple: #4B31B9;
$purple500: #3637B7;
$midPink: #FF67A7;
$darkestPink: #E92C7B;
$teal: #A1DED3;
$darkestTeal: #0598B8;
$aquamarine: #7CD9EE;
$orange50: #FCE2B9;
$orange: #FFBB49;
$midOrange: #FFC86D;
$darkestOrange: #E28D00;
$yellow: #FFDB58;
$darketsYellow: #CA9D00;
$caretColor: #A0BEFF;
$darkBlueBackground: #0A326C40;

$modalOverlay: rgba(#01031F, 0.48);

//usages of colours
$inputBackground: $lightBlue;
$disabledInputBackground: $lighterGray;
$errorInputBackground: $lightRed;
$errorInputLabel: $darkRed;
$colorText: $darkGray;
$error: $red;

$blackBox: 0px 10px 25px rgba($black, 0.25);

// Font size options
$fontsize: 16px;

$font9: #{math.div(9px, $fontsize)}rem;
$font10: #{math.div(10px, $fontsize)}rem;
$font11: #{math.div(11px, $fontsize)}rem;
$font12: #{math.div(12px, $fontsize)}rem;
$font13: #{math.div(13px, $fontsize)}rem;
$font14: #{math.div(14px, $fontsize)}rem;
$font15: #{math.div(15px, $fontsize)}rem;
$font16: #{math.div(16px, $fontsize)}rem;
$font17: #{math.div(17px, $fontsize)}rem;
$font18: #{math.div(18px, $fontsize)}rem;
$font20: #{math.div(20px, $fontsize)}rem;
$font22: #{math.div(22px, $fontsize)}rem;
$font24: #{math.div(24px, $fontsize)}rem;
$font25: #{math.div(25px, $fontsize)}rem;
$font26: #{math.div(26px, $fontsize)}rem;
$font28: #{math.div(28px, $fontsize)}rem;
$font32: #{math.div(32px, $fontsize)}rem;
$font36: #{math.div(36px, $fontsize)}rem;
$font40: #{math.div(42px, $fontsize)}rem;
$font42: #{math.div(42px, $fontsize)}rem;
$font55: #{math.div(55px, $fontsize)}rem;

$textFontFam: Texta, Arial, sans-serif;
$textFontFam2: Texta, Arial, sans-serif;
$textFontLight: Texta-Light, Arial, sans-serif;
$textFontBold: Texta-Bold, Arial, sans-serif;
$textFontMedium: Texta-Medium, Arial, sans-serif;
$textFontItalic: Texta-Italic, Arial, sans-serif;
$textFontItalicPT: PT Serif, serif;

$whiteBackgroundSectionWidth: min(100%, 51rem);

$primaryGradient: linear-gradient(270deg, $darkVibrantPurple 0%, $primaryBlue 100%);
$backgroundGradient: linear-gradient(180deg, #2a68ff 0%, #654acf 100%);
$backgroundGradientVertical: linear-gradient(90deg, #2a68ff 0%, #654acf 100%);
$darkTint: rgba(#0a326c, 0.25);
$lightTint: rgba(#0a326c, 0.16);
