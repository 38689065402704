@import "variables";
@import "mixins";

.button--base {
  border: none;
  background: none;
  padding: 0;
}

.button--default {
  position: relative;
  border-radius: 30px;
  color: #fff;
  background-color: $green;
  font-size: $font24;
  font-family: $textFontBold;
  padding: 15px 20px;
  display: block;
  height: auto;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 5px 10px 0 rgba(53, 88, 14, 0.25), 0 2px 4px 0 rgba(53, 88, 13, 0.23);
  &.blue {
    background-color: $lightBlue;
  }
  &.link {
    background-color: $white;
  }
  &.red {
    background-color: $red;
  }
  &.gray {
    background-color: $gray;
    color: $oldMidGray;
  }
  &.fit-content {
    width: fit-content;
    min-width: fit-content;
    padding: 10px 30px;
    font-weight: 400;
    font-size: 18px;
  }
  span { background-color: inherit; }

  .button--default__sign-icon {
    display: inline-block;
    position: absolute;
    right: 25px;
    @include icon(24px, 24px, $white, "../../public/images/icons/sign.svg");
  }
}

.button--icon {
  border-radius: 100%;
  color: $primaryBlue;
  background-color: rgba($primaryBlue, 0.2);
  height: 1.5rem;
  width: 1.5rem;
  display: grid;
  place-items: center;
  margin: 0;
  padding: 0;
  font-family: $textFontFam;
  font-size: $font16;
}

.button--card,
.button--primary,
.button--secondary,
.button--tertiary{
  padding: 0.5rem 1rem;
  line-height: 1.5;
  border-radius: 8px;
}

.button--large {
  padding: 0.75rem 1rem;
}

.button--small {
  padding: 0.25rem 0.75rem;
}

.button--card {
  font: $font16/1.5 $textFontMedium;
  background-color: $white;
  color: $black;

  &:disabled {
    background-color: rgba($white, 0.5);
  }
}

.button--primary {
  background-color: $primaryBlue;
  color: $white;
  font-family: $textFontMedium;

  &:disabled {
    background-color: $secondaryBlue;
    color: $primaryBlue;
  }

  &:not(:disabled):not(.button--dark) {
    &:hover {
      color: $darkPrimaryBlue;
      outline: 1px solid $darkPrimaryBlue;
      background-color: $primaryBlue;
    }

    &:active {
      background-color: $darkPrimaryBlue;
      color: $darkerBlue;
      outline: 1px solid $darkerBlue;
    }
  }

  &.button--dark {
    background-color: $white;
    color: $darkVibrantPurple;

    &:disabled {
      background-color: rgba($white, 0.5);
    }
  }

}

.button--secondary {
  font-size: $font16;
  background-color: $white;
  color: $primaryBlue;
  border: 1px solid $primaryBlue;

  &:not(:disabled) {
    &:hover {
      background-color: $lighterBlue;
    }

    &:active {
      background-color: $primaryBlue;
      color: $darkPrimaryBlue;
      border: 1px solid $darkPrimaryBlue;
    }
  }

  &.button--dark {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
  }
}

.button--tertiary {
  color: $primaryBlue;
  background-color: $white;
  font-family: $textFontMedium;

  &:disabled {
    background-color: rgba($white, 0.5);
    color: rgba($primaryBlue, 0.5);
  }
}
