@import "../../sass/variables.scss";
@import "../../sass/mixins";

.text {
    font-size: $font16;
    color: $black;
    line-height: 1.5;
}
.largeText {
    font: $font20 $textFontMedium;
    color: $black;
}
.blackText {
    font-size: $font16;
    line-height: 1.5;
    color: $black;
}
.lineThrough {
    text-decoration: line-through;
    color: $textFieldGray;
}
.h1 {
    font: $font24 $textFontBold;
    color: $black;
    line-height: 1.5;
}
.h2 {
    font: $font20 $textFontMedium;
    color: $black;
}
.h4 {
    font: $font20 $textFontBold;
    color: $black;
    margin: 0;
}
.error {
    font-size: $font16;
    line-height: 1.5;
    color: $red;
}
