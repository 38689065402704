@font-face {
  font-family: 'Texta';
  font-weight: normal;
  font-style: normal;
  src: url('../../public/fonts/TextaBasicFamily/Texta-Regular/Texta-Regular.woff') format('woff'),
  url('../../public/fonts/TextaBasicFamily/Texta-Regular/Texta-Regular.ttf') format('truetype'),
  url('../../public/fonts/TextaBasicFamily/Texta-Regular/Texta-Regular.svg#Texta-Black') format('svg'),
  url('../../public/fonts/TextaBasicFamily/Texta-Regular/Texta-Regular.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Texta-Light';
  font-weight: normal;
  font-style: normal;
  src: url('../../public/fonts/TextaBasicFamily/Texta-Light/Texta-Light.woff') format('woff'),
  url('../../public/fonts/TextaBasicFamily/Texta-Light/Texta-Light.ttf') format('truetype'),
  url('../../public/fonts/TextaBasicFamily/Texta-Light/Texta-Light.svg#Texta-Black') format('svg'),
  url('../../public/fonts/TextaBasicFamily/Texta-Light/Texta-Light.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Texta-Bold';
  font-weight: bold;
  font-style: normal;
  src: local('Texta Bold'), local('Texta-Bold'),
  url('../../public/fonts/TextaBasicFamily/Texta-Bold/Texta-Bold.woff') format('woff'),
  url('../../public/fonts/TextaBasicFamily/Texta-Bold/Texta-Bold.ttf') format('truetype'),
  url('../../public/fonts/TextaBasicFamily/Texta-Bold/Texta-Bold.svg#Texta-Black') format('svg'),
  url('../../public/fonts/TextaBasicFamily/Texta-Bold/Texta-Bold.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Texta-Italic';
  font-weight: normal;
  font-style: normal;
  src: local('Texta Italic'), local('Texta-Italic'),
  url('../../public/fonts/TextaBasicFamily/Texta-Italic/Texta-Italic.woff') format('woff'),
  url('../../public/fonts/TextaBasicFamily/Texta-Italic/Texta-Italic.ttf') format('truetype'),
  url('../../public/fonts/TextaBasicFamily/Texta-Italic/Texta-Italic.svg#Texta-Black') format('svg'),
  url('../../public/fonts/TextaBasicFamily/Texta-Italic/Texta-Italic.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Texta-Medium';
  font-weight: normal;
  font-style: normal;
  src: url('../../public/fonts/TextaBasicFamily/Texta-Medium/Texta-Medium.woff') format('woff'),
        url('../../public/fonts/TextaBasicFamily/Texta-Medium/Texta-Medium.ttf') format('truetype'),
        url('../../public/fonts/TextaBasicFamily/Texta-Medium/Texta-Medium.svg#Texta-Black') format('svg'),
        url('../../public/fonts/TextaBasicFamily/Texta-Medium/Texta-Medium.eot?#iefix') format('embedded-opentype');
}
