@import "variables";

//mixsins
@mixin icon-button($width, $height, $icon:false) {
  margin: 0;
  width: $width;
  height: $height;
  border: none;
  background-color: transparent;
  text-align: center;
  outline: none;
  padding: 0;
  @if $icon {
    background: url($icon) no-repeat;
  }
}

@mixin icon-button2($width, $height, $icon:false, $iconColor: false) {
  margin: 0;
  width: $width;
  height: $height;
  border: none;
  background-color: transparent;
  text-align: center;
  outline: none;
  padding: 0;
  @if $icon {
    mask: url($icon) no-repeat 50% 50%;
    -webkit-mask: url($icon) no-repeat 50% 50%;
  }
  @if $iconColor {
    background: $iconColor;
  }
}

@mixin yes-no-button($width, $height, $check:false, $checked-colour:$red, $urlPrefix:"") {
  margin: 0;
  width: $width;
  height: $height;
  font: $font13 $textFontBold;
  text-transform: uppercase;
  border: solid;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: $white;
  background-color: transparent;
  text-align: center;
  outline: none;
  padding: 0;
  @if $check {
    background-position: 5px 5px;
    background-size: 16px 16px;
    background-color: $checked-colour;
    color: $white;
  }
}

@mixin toggle-button($width, $height, $check:false, $checked-colour:$red, $urlPrefix:"") {
  margin: 0;
  width: $width;
  height: $height;
  font: $font13 $textFontBold;
  color: $white;
  text-align: center;
  outline: none;
  padding: 0;
  &:not(:last-of-type) {
    border-right: 0;
  }
  &:first-of-type {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:last-of-type {
    border-left: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  @if $check {
    background-color: $checked-colour;
    color: $white;
  }
}

@mixin ellipse-button($width, $height, $check:false) {
  margin: 0;
  width: $width;
  height: $height;
  font: $font13 $textFontBold;
  text-transform: uppercase;
  border: none;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  color: $blue;
  background-color: $white;
  text-align: center;
  outline: none;
  padding: 0;
  @if $check {
    background: url("../../public/images/icons/icon_checkmark_white.svg") no-repeat;
    background-position: 5px 5px;
    background-size: 16px 16px;
    background-color: $green;
    color: $white;
  }
}

@mixin pure-button($width, $height) {
  margin: 0;
  width: $width;
  height: $height;
  font: $font18 $textFontBold;
  color: $blue;
  line-height: $defaultLineHeight;
  border: none;
  background-color: transparent;
  text-align: center;
  outline: none;
  padding: 0;
}

@mixin icon($width, $height, $color, $svgPath) {
  width: $width;
  height: $height;
  background: $color;
  margin: 0 auto;
  mask: url($svgPath) no-repeat 50% 50%;
  -webkit-mask: url($svgPath) no-repeat 50% 50%;
}

@mixin label {
  font: $font20 $textFontFam;
  font-weight: 500;
  line-height: $defaultLineHeight;
  color: $white;
}

@mixin title {
  font: $font40 $textFontBold;
  line-height: $defaultLineHeight;
  color: $white;
}

@mixin subtitle {
  font: $font28 $textFontBold;
  line-height: $defaultLineHeight;
  color: $white;
}

@mixin notice {
  font: $font20 $textFontFam;
  line-height: $defaultLineHeight;
  color: $white;
}

@mixin placeholder-style {
  &::-webkit-input-placeholder {
    opacity: 1;
    @content;
  }

  &:-moz-placeholder {
    opacity: 1;
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-ms-input-placeholder {
    opacity: 1;
    @content;
  }
}

/**
    DEMO: http://codepen.io/anon/pen/wKxNZV

    @params

       Radio
           $size             - diameter of hit area (will generally be the same as $outerSize)
           $innerSize        - diameter of inner circle
           $outerSize        - diameter of outer circle
           $borderColour     - colour of the border surrounding the circle
           $borderWidth      - thickness of the border surrounding the cicle
           $backgroundColour - colour of the larger outer circle
           $innerColour      - colour of the "selected" inner circle
           $buffer           - space to the right of the radio before the label text
           $marginRight      - margin on the right hand side of the label text

       Checkbox
           $size             - Size of the side of the square
           $borderColour     - Square border colour
           $borderWidth      - Thickness of border
           $borderRadius     - Radius of border
           $backgroundColour - Square background
           $innerColour      - Colour of the "check" icon
           $buffer           - space to the right of the checkbox before the label text
           $marginRight      - margin on the right hand side of the label text
           $fontsize         - Size of the character (can be bigger than the box)
           $checksize        - If this is set it will set the check background size
           $checkSrc         - If this is set use this image instead
*/

@mixin makeCheckbox($size,$borderColour,$borderWidth,$borderRadius,$backgroundColour,$innerColour,$buffer,$marginRight,$fontsize,$checksize:'',$checkSrc:"icon_checkmark.svg", $checkedBackgroundColour:'') {

  /* Base for label styling */
  [type="checkbox"] {
    position: absolute;
    left: -9999em;
  }

  [type="checkbox"] + label{
    cursor: pointer;
    min-height: $size;
    line-height: $size !important;
    margin-right: $marginRight !important;
  }

  .label-text-checkbox {
    vertical-align: middle;
    line-height: 1em;
    display: inline-block;
    padding-left: calc($size + $buffer);
  }

  .label-text-large-checkbox {
    margin-left: 5px;
    line-height: 1em;
    display: inline-block;
    padding-left: calc($size + $buffer);
  }

  // circles container
  [type="checkbox"] + label > span:first-child {
    position: absolute;
    width: $size;
    height: $size;
    display: inline-block;
  }


  /* radio aspect */
  [type="checkbox"] + label > span:first-child:before {
    content: '';
    position: absolute;
    width: $size;
    height: $size;
    border: $borderWidth solid $borderColour;
    background: $backgroundColour;
    border-radius: $borderRadius;

    top: calc(($size - $size) * 0.5);
    left: calc(($size - $size) * 0.5);
  }
  /* checked mark aspect */
  [type="checkbox"] + label > span:first-child:after {
    background: url("../../public/images/icons/#{$checkSrc}") no-repeat center;
    content: '';
    position: absolute;
    transition: all .2s;
    border-radius: 0;
    width: $size;
    height: $size;
    top: 2px;
    left: 2px;
    line-height: $size;
    text-align: center;
    font-style: normal;
    font-size: $fontsize;
    color: $innerColour;
    @if $checksize != '' {
      background-size: $checksize;
    }
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label > span:first-child:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + label > span:first-child:after {
    opacity: 1;
    transform: scale(1);
  }
  [type="checkbox"]:checked + label > span:first-child:before {
    @if $checkedBackgroundColour != '' {
      background-color: $checkedBackgroundColour
    }
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label > span:first-child:before,
  [type="checkbox"]:disabled:checked + label > span:first-child:before {
    box-shadow: none;
  }
  [type="checkbox"]:disabled:checked + label > span:first-child:after {
    color: white;
  }
  [type="checkbox"]:disabled + label > span:first-child {
    color: white;
    opacity: 0.7;
  }

  /* accessibility */
  [type="checkbox"]:focus + label > span:first-child:before {
    border: $borderWidth solid $innerColour;
  }

}


@mixin makeRadio($size,$innerSize,$outerSize,$borderColour,$borderWidth,$backgroundColour,$innerColour,$buffer,$marginRight) {

  $radioOuterSize: $outerSize;
  $radioInnerSize: $innerSize;
  /* Base for label styling */
  [type="radio"] {
    position: absolute;
    left: -9999em;
  }

  [type="radio"] + label{
    cursor: pointer;
    min-height: $size;
    line-height: $size !important;
    margin-right: $marginRight !important;
  }

  .label-text-radio {
    vertical-align: middle;
    line-height: $defaultLineHeight;
    display: inline-block;
    padding-left: $size + $buffer;
    font: $font20 $textFontLight;
    background: rgba($black,0.1);
    border-radius: 5px;
    padding: 15px 15px 15px 45px;
  }
  [type="radio"]:checked + label{
    .label-text-radio {
      border: 2px solid $white;
    }
  }

  // circles container
  [type="radio"] + label > span:first-child {
    margin: 20px 0 0 15px;
    position: absolute;
    width: $size;
    height: $size;
    display: inline-block;
  }


  /* radio aspect */
  [type="radio"] + label > span:first-child:before {
    content: '';
    position: absolute;
    width: $radioOuterSize;
    height: $radioOuterSize;
    border: $borderWidth solid $borderColour;
    background: $backgroundColour;
    border-radius: 50%;

    top: ($radioInnerSize - $radioOuterSize) * 0.5;
    left: ($radioInnerSize - $radioOuterSize) * 0.5;
  }
  /* checked mark aspect */
  [type="radio"] + label > span:first-child:after {
    content: '';
    position: absolute;
    transition: all .2s;
    background: $innerColour;
    border-radius: 50%;
    width: $radioInnerSize;
    height: $radioInnerSize;
    top: 2px;
    left: 2px;
  }
  /* checked mark aspect changes */
  [type="radio"]:not(:checked) + label > span:first-child:after {
    opacity: 0;
    transform: scale(0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
  }
  [type="radio"]:checked + label > span:first-child:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="radio"]:disabled + label > span:first-child:before {
    box-shadow: none;
  }
  [type="radio"]:disabled:checked + label > span:first-child:after {
    color: $innerColour;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)" !important;
  }
  [type="radio"]:disabled + label > span:first-child {
    color: $innerColour;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)" !important;
  }

  /* accessibility */
  [type="radio"]:focus + label > span:first-child:before {
    border: $borderWidth solid $innerColour;
  }
}

//Media Queries - values from https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/

// This should be rarely used. We should first apply styling for mobile and then use media queries to provide styling for larger devices
@mixin forPhoneOnly {
  // Equivalent to ~599px at root font-size of 16
  @media (max-width: 37.49rem) {
    @content
  }
}

@mixin forTabletPortraitUp {
  // Equivalent to  600px at root font-size of 16
  @media (min-width: 37.5rem) {
    @content
  }
}

@mixin forTabletLandscapeUp {
  // Equivalent to 900px at root font-size of 16
  @media (min-width: 56.25rem) {
    @content
  }
}

@mixin forDesktopUp {
  // Equivalent to 1200px at root font-size of 16
  @media (min-width: 75rem) {
    @content
  }
}

// OLD MEDIA QUERIES - DO NOT USE
//991px
@mixin alphaSize {
  @media all and (max-width: $alpha) {
      @content
  }
}

//767px
@mixin betaSize {
  @media all and (max-width: $beta) {
      @content
  }
}

@mixin whiteBackgroundPage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    padding: 1.5rem 1rem;
    align-items: center;

    @include forTabletPortraitUp {
      flex: unset;
    }
}
@mixin whiteBackgroundSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: $whiteBackgroundSectionWidth;

  @include forTabletPortraitUp() {
    padding: 2rem;
  }
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}