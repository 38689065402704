@import "variables";
@import "mixins";

.sign-wrapper {
  position: fixed;
  z-index: 95;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: .2s;
  &.open {
    visibility: visible;
    opacity: 1;
    transition: 0s;
    .sign-overlay {
      visibility: visible;
      opacity: 1;
    }
    .sign {
      transform: translateY(-100%);
    }
  }
}

.sign-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .4);
  transition: .2s;
}

.sign {
  position: absolute;
  z-index: 1;
  top: 20%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  outline: none;
  &.full-height {
    height: 85vh;
    top: 0;
    bottom: 15%;
    .signature-pad-block {
      // tenant sign preview take 33.3vh and other parts min-height is 177px and height is 32vh
      min-height: calc(66.7vh - 177px);
      height: 35vh;
    }
  }
}

.tenant-signature-preview {
  height: 33.3vh;
  background-image: linear-gradient(301deg, #833be2, $backgroundGradientBlue);
  text-align: center;
  padding: 40px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .label {
    flex: 0;
    margin-bottom: 5px;
  }
  > img {
    flex: 0 0 80%;
    max-height: 80%;
    filter: brightness(1000%);
    -webkit-filter: brightness(1000%);
  }
}

.sign-modal,
.sign {
  .sign-header {
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    display: flex;
    border-bottom: 1px solid #d3d6db;
    text-align: center;
    min-height: 59px;
    background-color: $white;
    button {
      width: 66px !important;
      height: 55px !important;
    }

    .button-cancel {
      @include pure-button(auto, auto);
      color: #fe5e7d;
    }

    .button-done {
      @include pure-button(auto, auto);
      &:disabled{
        pointer-events: none;
        color: $gray;
      }
      .splash-preloader {
        background-image: radial-gradient(circle at top, $oldMidGray, transparent);
        &.inverse {
            background-image: linear-gradient($white, $white), radial-gradient(circle at top, $white, transparent);
        }
        &:after {
          background-color: $white;
        }
      }
    }

    h3 {
      font: $font20 $textFontBold;
      margin: 0;
      color: #36383d;
      overflow-wrap: anywhere;
    }

    span {
      display: block;
      margin-top: 4px;
      opacity: 0.7;
      font-size: $font14;
      line-height: $defaultLineHeight;
      color: #36383d;
    }
  }

  .signature-modal-header {
    background-color: $red;
    justify-content: center;
    h3 {
      color: $white;
      strong {
        color: $black;
      }
    }
  }

  .sign-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    min-height: 56px;
    height: 8vh;
    border-top: 1px dashed $gray;
    background-color: $white;


    p {
      padding-left: 32px;
      font: $font16 $textFontBold;
      color: $blue;
      line-height: $defaultLineHeight;
      background: url("../../public/images/icons/icon_airplane.svg") no-repeat;
      background-position-y: 2px;
      cursor: pointer;
      margin: 16px;
    }

    .button-email {
      @include pure-button(auto, auto);
      padding-left: 32px;
      font: $font16 $textFontBold;
      color: $blue;
      line-height: $defaultLineHeight;
      background: url("../../public/images/icons/icon_airplane.svg") no-repeat;
      background-position-y: 2px;
      cursor: pointer;
      margin: 16px;
      z-index: 10000;
    }

    .button-erase {
      @include icon-button(24px, 24px, "../../public/images/icons/icon_eraser.svg");
      position: relative;
      z-index: 10000;
      margin: 16px;
    }
  }
}

.signature-pad-block {
  background-color: $white;
  &.signature-background {
    .signCanvas {
      background-image: url("../../public/images/signature_new.svg");
      background-size: 280px;
      background-repeat:no-repeat;
      background-position:center;
      opacity: 1;
    }
  }
  &.initial-background {
    .signCanvas {
      opacity: 1;
      background-image: url("../../public/images/signature-small.svg");
      background-repeat:no-repeat;
      background-position:center;
      background-size: 200px;
    }
  }
}

@media screen and (max-width: 1024px) {
  #github {
    display: none;
  }
}
