@import "../variables.scss";

.card {
  border-radius: 24px;
  background-color: $white;
  padding: 1.25em;
  position: relative;
  box-shadow: $blackBox;

  &.square {
    border-radius: 8px;
  }
    
  &.smallShadow {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  }

  &.noShadow {
    box-shadow: none;
  }
  &.blackBorder {
    border: 1px solid $black;
    box-shadow: none;
  }
  &.grayBorder {
    border: 1px solid $midGray;
    box-shadow: none;
  }
}
