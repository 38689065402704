@import "../../sass/variables.scss";
@import "../../sass/mixins";

.requestModal {
  position: relative;

  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    color: $black;
    cursor: pointer;

    .closeIcon {
      color: $primaryBlue;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .submitButton {
      display: flex;
      gap: 0.5rem;
      align-self: flex-start;

      .spinner {
        display: inline-block;
        vertical-align: middle;
        align-self: center;
        border: 2px solid transparent;
        border-top: 2px solid $primaryBlue;
        border-right: 2px solid $primaryBlue;
        border-bottom: 2px solid $primaryBlue;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      &:disabled {
        background-color: $lighterGray;
        color: $textFieldGray;
      }
    }
  }
}

@include forPhoneOnly {
  .requestModal {
    padding: 5rem 1rem 1rem;
  }
}

@include forTabletPortraitUp {
  .body {
    height: 100%;
    background: $lighterGray;

    @include whiteBackgroundPage;
  }

  .section {
    @include whiteBackgroundSection;
  }
}