@import "normalize";
@import "fonts";
@import "variables";
@import "mixins";

// style options
* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  @media screen and (max-width: 350px) {
    font-size: 85%;
  }
}

body,
html {
  height: 100%;
  font-family: $textFontFam;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* prevent scrolling in windows phone */
  -ms-touch-action: none;
  &.no-scroll {
    overflow: hidden;
  }
}


main {
  padding-top: 25px;
}

#root {
  width: 100%;
  min-height: 100%;
  display: flex;
}

.content {
  margin: auto;
  width: 100%;
}

.hideClass {
  display: none !important;
}

@media screen and (max-aspect-ratio: 13/9) {
  .portrait {
    display: none;
  }
}
@media screen and (min-aspect-ratio: 13/9) {
  .portrait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:999;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(90deg,  $backgroundGradientBlue, #833be2);
    .landscape-img {
      margin-bottom: 20px;
    }
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

i {
  font-family: $textFontItalic;
}

h1, h2 {
  margin: 0;
}

p {
  margin: 0;
}

input {
  outline: none;
}

button {
  outline: none;
}

.hidden {
  display: none;
}

footer {
  display: flex;
}



// media
.mobile-show {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

.logo-big {
  height: 24px;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input,
input:focus {
  outline: none;
}

button,
button:focus {
  outline: none;
}

.video embed,
.video object,
.video iframe {
  max-width: 100%;
  height: auto;
}

a {
  color: white;
}

.confirm {
  width: 96%;
  &-container {
    background-color: $white;
    outline: none;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    padding: 0 16px;
    color: #37393d;
  }

  &-title {
    color: #37393d;
    text-transform: uppercase;
    border-bottom: 1px solid #e2e2e2;
    padding-top: 20px;
    height: 55px;
    text-align: center;
  }

  &-label {
    padding-top: 10px;
    font-size: $font20;
    color: #37393d;
  }

  &-details {
    padding-top: 25px;
    text-align: center;
    display: flex; //flex
    flex-direction: column;

    .checkbox {
      @include makeCheckbox(15px, #9d9d9b, 2px, 50%, rgba(255, 255, 255, 0.2), #9d9d9b, 13px, 50px, 16px, 13px, "icon_checkmark_grey.svg");
      text-align: left;
      margin-top: 5px;
      ul {
        margin-left: 50px;
        list-style-type: disc;
        font: 1.2rem $textFontFam;
      }
    }
    .label-text-checkbox {
      font: 1.2rem $textFontFam;
      text-align: left;
    }
  }


  &-footer {
    padding: 20px 0 22px;
    text-align: center;
    button {
      background: #66db29;
      color: #FFF;
      display: block;
      font-size: 150%;
      margin: auto;
      min-width: 200px;
      border: 0;
      border-radius: 25px;
      padding: 12px;
      -webkit-box-shadow: 0 3px 11px 0 rgba(0, 0, 0, .5);
      box-shadow: 0 3px 11px 0 rgba(0, 0, 0, .5);
    }

    button[disabled] {
      background-color: #d3d6db;
      color: #9d9d9b;
    }
  }
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(255,255,255,0.35);
  margin:19px 0 19px;
  &--large {
    margin:2rem 0;
  }
}
.line-dotted {
  border: none;
  border-top: 1px dotted rgba(255,255,255,0.35);
  height: 1px;
  width: 100%;
  background-color: rgba(255,255,255,0.35);
  margin:19px 0 19px;
  background-image: linear-gradient(to right, $blue 50%, rgba(255,255,255,0.35) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
.space {
  width: 100%;
  margin: 5px 0;
}
.form-input {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  &.error {
    input {
      border-bottom: 2px solid $red;
    }
    &.active {
      label {
        color: $red;
      }
    }
  }
  input {
    width: 100%;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    background: transparent;
    color: #fff;
    height: 45px;
    font-size: $font28;
    position: relative;
    z-index: 2;
    padding: 0;
  }
  label {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform: translateY(10px);
    color: rgba(255, 255, 255, .3);
    font-size: $font28;
    font-family: $textFontBold;
    transition: .35s all;
    &.label-medium {
      font-size: $font20;
    }
  }
  &.active label, input:focus + label {
    transform: translateY(-10px);
    font-size: $font20;
    color: $white;
  }
  // remove number input arrows
  // Chrome, Safari, Edge, Opera
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  // Firefox
  input[type=number] {
    -moz-appearance: textfield;
  }
}
.submit-button {
  background: $green;
  color: $white;
  display: block;
  margin: auto;
  min-width: 96%;
  font-size: $font24;
  font-family: $textFontBold;
  border: 0;
  border-radius: 25px;
  padding: 12px;
  -webkit-box-shadow: 0 3px 11px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, .5);
}
.submit-button[disabled] {
  background-color: #d3d6db;
  color: #9d9d9b;
}

// Background color classes
.light-blue {
  background:$lightBlue;
}
.disabled {
  pointer-events: none;
}
.crossed {
  text-decoration: line-through
}
.float-right {
  float: right;
  clear: both;
}
.for-sm-modal {
  @include betaSize {
    display: flex !important;
  }
  display: none !important;
}

//NEW SECTION
.for-lg-modal {
  @include betaSize {
    display: none !important;
  }
}
.item-list {
  padding: 10px;
}

.bullet-list {
  margin-left: 10px;
  // Remove extra margin added from list
  margin-top: -16px;
  li {
    margin: 5px 0 0 5px;
  }
  .header {
    margin: 8px 0 8px -8px;
    font-weight: 700;
  }
  > li:before {
    content: '\2022';
    font-size: $font32;
    line-height: 1;
    margin-left: -15px;
    padding-right: 5px;
  }
}
.details-table {
  td {
    min-width: 50vw;
    padding: 10px 0;
  }
}

.margin-bottom-0 {
  margin-bottom : 0px !important;
}
.margin-bottom-25 {
  margin-bottom : 25px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-1-rem {
  margin-top: 1rem !important;
}
.margin-top-5-rem {
  margin-top: 5rem !important;
}
