@import '@flk/design-system/variables.scss';

.container {
    font-size: $font16;
    font-weight: 400;
    line-height: 1.5;
    color: $black;
    background: $blue100;
    padding: 1rem;
    border-radius: 8px;

    &.info {
        background-color: $blue100;
    }

    &.warning {
        background-color: $yellow300;
    }

    &.error {
        background-color: $red50;
    }
    
    &.success {
        background-color: $green50;
        
        .icon {
            color: $white;
            background-color: $green500;
            border-radius: $flk-shape-round;
            padding: 0.125rem;
        }
    }

    .header {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
            flex-shrink: 0;
        }
    }

    .content {
        margin: 1rem 0 0.5rem 0;
        font-size: $font16;
        line-height: 1.5;
    }
}

.infoIcon {
    padding: 0.25rem;
    background: $blue400;
    border-radius: 16px;
    color: $white;
}