@import "../../sass/variables.scss";
@import "../../sass/mixins";

.header {
    background: $primaryGradient;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem;
    // undo leaky style from header.scss
    height: unset;

    &.documentHeader {
        justify-content: flex-end;
    }

    .step {
        font-size: $font18;
        line-height: 1.3;
        height: 2rem;
        padding: 0.25rem 0.75rem;
        align-items: center;
        color: $white;
        background: rgba($purple500, 0.6);
        border-radius: 8px;
    }
    .infoButton {
        margin-left: auto;
        width: 24px;
        height: 24px;
        background: $white;
        border-radius: 16px;
        .icon {
            color: $primaryBlue;
            width: 12px;
            height: 12px;
        }
    }
}