@import "../sass/variables.scss";

.rotateScreen {
    background: $white;
    z-index:999;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    &.mobile {
        @media screen and (max-aspect-ratio: 13/9) {
            display: none;
        }
    }
    
    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        padding-top: 2rem;
    }

}