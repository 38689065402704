@import "variables";
@import "mixins";

.furnished-modal {
  background-color: $white;
  outline: none;
  overflow: hidden;
  border-radius: 4px;
  width: 255px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .modal-content {
    margin-top: 16px;
    box-shadow: inset 0 -0.5px 0 0 #d3d6db;
    display: flex;
    justify-content: center;
    .text {
      margin: 0 auto;
      width: 225px;
      height: 78px;
      font-family: $textFontFam;
      font-size: 18px;
      font-weight: 500;
      line-height: $defaultLineHeight;
      text-align: center;
      color: #272e42;
      padding-bottom: 16px;
    }
  }

  .modal-footer {
    display: flex;
    height: 49px;
    justify-content: center;
    align-items: center;
    button {
      background-color: transparent;
      border: none;
      font-family: $textFontFam;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: #427cfe;
    }
  }
}
