@import "../../sass/variables.scss";
@import "../../sass/mixins";

.changeRequestInfo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($textFieldGray, 0.3);
  z-index: 1;
  padding: 1rem;

  @include forTabletPortraitUp {
    .toast {
      position: absolute;
      top: 24px;
      right: 24px;
      max-width: 40ch;
    }
  }
  
}