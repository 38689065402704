

.maintenance {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;
  }
  .maintenance-text-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
    gap: 1em;
    .text {
      text-align: center;
    }
  }
}
