@import "../sass/variables.scss";

.continueOnMobile {
    background: $white;
    z-index:999;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    .container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 50rem;
        // align to center
        margin: calc((100vh - 34rem) / 2) auto;
        .text {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;
        }
        .qrCodeSection {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: 13.5rem auto;
            // p - phone image
            // q - qr code
            // l - link section
            grid-template-areas: "p q"
                                 "p l";
            gap: 1.5rem;
            padding: 2rem 2rem 0 2rem;
            background: $lightestGrey;

            .phone {
                grid-area: p;
                margin-right: 1rem;
            }
            .qr {
                grid-area: q;
                border-radius: 4px;
                background: $white;
                padding: 1.25rem;
                box-shadow: 0px 3px 20px 0px rgba($black, 0.15);
                place-self: flex-start;
            }
            .copyLinkSection {
                grid-area: l;
                padding: 1rem 1rem 1rem 2rem;
                border-radius: 4px;
                background: $quaternaryBlue;
                width: 100%;
                height: 5.25rem;

                display: flex;
                justify-content: space-between;
                .linkSection {
                    max-width: 20.3125rem;
                    .link {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        color: $primaryBlue;
                    }
                }
                .copyButton {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                }
            }
        }
    }

}