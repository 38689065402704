@import "variables";
@import "mixins";

.navigation-modal,
.navigation-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    outline: none;
    overflow: scroll;
    width: 331px;
    background: linear-gradient(331deg, #823be2 0%, #7359e7 34%, #6f61e9 35%, $backgroundGradientBlue 100%);
}

.navigation-container {
    ul {
        padding: 30px;

        .menu-item {
            font: $font20 $textFontBold;
            line-height: $defaultLineHeight;
            margin-bottom: 15px;
            position: relative;
            color: $white;

            a {
                color: $white;
                text-decoration: none;
            }

            span {
                opacity: 0.3;
            }

            &.confirmed {
                &:before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: -20px;
                    width: 10px;
                    height: 10px;
                    background: url("../../public/images/icons/confirm.svg") no-repeat 50% 50%;
                    background-size: cover;
                }
            }

            &.decline {
                &:before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: -20px;
                    width: 10px;
                    height: 10px;
                    background: url("../../public/images/icons/icon_cancel_24_white.svg") no-repeat 50% 50%;
                }
            }

            &.current {
                a,
                span {
                    opacity: 1;
                    border-bottom: 1px solid;
                    padding-bottom: 3px;
                }
            }

            &.visited {
                a,
                span {
                    opacity: 1;
                    padding-bottom: 3px;
                }
            }
        }
    }
}
